import React from 'react';

/**
 * Notes icons.
 */
function NotesIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='23'
      height='23'
      viewBox='0 0 600 600'
    >
      <path
        id='Selection'
        fill='currentColor'
        stroke='black'
        strokeWidth='1'
        d='M 243.00,34.00
           C 248.87,35.43 255.13,35.43 261.00,34.00
             261.00,34.00 280.00,43.90 280.00,43.90
             290.33,51.06 296.27,63.03 299.00,75.00
             307.66,71.20 316.95,69.22 326.00,66.58
             326.00,66.58 354.00,57.74 354.00,57.74
             361.42,55.68 376.47,52.77 382.00,48.00
             387.00,48.44 406.03,43.20 410.00,40.00
             414.73,40.07 426.35,36.92 430.00,34.00
             437.53,35.35 439.47,35.35 447.00,34.00
             447.00,34.00 452.00,35.00 452.00,35.00
             455.97,38.49 459.43,38.87 464.00,41.32
             470.30,44.70 476.98,50.27 481.24,56.00
             489.04,66.49 492.69,82.38 496.15,95.00
             496.15,95.00 510.42,143.00 510.42,143.00
             510.42,143.00 530.28,208.00 530.28,208.00
             530.28,208.00 564.98,324.00 564.98,324.00
             564.98,324.00 571.02,345.00 571.02,345.00
             571.02,345.00 580.63,377.00 580.63,377.00
             582.14,382.79 587.94,403.20 591.00,407.00
             591.00,407.00 588.35,428.00 588.35,428.00
             584.91,442.47 575.32,454.95 562.00,461.63
             555.90,464.69 543.75,468.67 537.00,470.54
             531.97,471.93 526.00,472.57 522.00,476.00
             514.18,475.86 504.63,480.34 497.04,482.63
             491.90,484.18 489.31,483.33 485.00,487.00
             480.75,486.94 479.26,487.20 476.00,490.00
             468.95,489.37 461.93,493.36 455.17,495.17
             450.05,496.54 442.97,497.59 439.00,501.00
             431.41,500.87 424.21,504.53 417.00,506.54
             412.19,507.87 405.74,508.80 402.00,512.00
             398.97,511.95 394.23,511.81 393.00,515.00
             386.45,514.42 380.21,517.77 374.04,519.63
             370.15,520.80 368.34,520.33 365.00,523.00
             360.75,522.94 359.26,523.20 356.00,526.00
             356.00,526.00 353.00,527.00 353.00,527.00
             347.65,526.53 341.08,528.48 337.00,532.00
             332.14,531.50 329.10,532.27 325.00,535.00
             325.00,535.00 319.00,537.00 319.00,537.00
             319.00,537.00 316.00,538.00 316.00,538.00
             310.53,537.77 304.19,539.39 300.00,543.00
             295.13,542.88 285.76,544.98 282.00,548.00
             282.00,548.00 279.00,549.00 279.00,549.00
             274.75,548.94 273.26,549.20 270.00,552.00
             265.07,551.56 246.33,557.11 242.00,560.00
             242.00,560.00 236.00,562.00 236.00,562.00
             236.00,562.00 233.00,563.00 233.00,563.00
             228.51,562.82 226.48,563.03 223.00,566.00
             223.00,566.00 201.00,567.00 201.00,567.00
             201.00,567.00 195.00,566.00 195.00,566.00
             192.31,563.43 177.52,555.64 174.00,555.00
             172.51,551.82 167.01,543.91 163.83,542.60
             161.36,541.58 146.69,542.00 143.00,542.00
             143.00,542.00 83.00,542.00 83.00,542.00
             74.73,542.00 58.27,541.23 51.00,543.00
             47.56,540.36 44.02,540.18 40.00,538.55
             34.49,536.30 26.15,531.29 22.33,526.74
             18.20,521.83 14.84,516.06 12.70,510.00
             11.76,507.34 11.78,503.09 9.00,502.00
             10.77,494.73 10.00,478.27 10.00,470.00
             10.00,470.00 10.00,411.00 10.00,411.00
             10.00,411.00 10.00,220.00 10.00,220.00
             10.00,220.00 10.00,151.00 10.00,151.00
             10.00,134.26 8.09,121.27 16.88,106.00
             19.77,100.97 25.19,95.17 30.00,91.95
             34.24,89.12 39.31,87.53 43.00,84.00
             43.00,84.00 53.28,82.90 53.28,82.90
             53.28,82.90 64.00,82.90 64.00,82.90
             64.00,82.90 90.00,82.90 90.00,82.90
             90.00,82.90 204.00,82.90 204.00,82.90
             204.01,68.53 209.78,53.48 222.00,44.90
             233.25,37.00 238.71,37.45 243.00,34.00 Z
           M 255.00,60.00
           C 252.71,57.45 250.29,57.45 248.00,60.00
             235.13,56.67 227.24,72.27 227.00,83.00
             227.00,83.00 256.00,83.00 256.00,83.00
             263.82,83.00 268.51,83.81 276.00,81.00
             275.51,78.39 274.81,74.37 273.78,72.01
             270.68,64.93 262.98,58.40 255.00,60.00 Z
           M 565.00,413.00
           C 567.22,404.91 562.67,396.69 560.42,389.00
             560.42,389.00 543.86,334.00 543.86,334.00
             543.86,334.00 519.72,254.00 519.72,254.00
             519.72,254.00 500.28,188.00 500.28,188.00
             500.28,188.00 480.12,121.00 480.12,121.00
             480.12,121.00 471.14,92.00 471.14,92.00
             468.32,82.15 466.90,72.48 458.96,65.18
             454.40,61.00 439.06,54.36 434.00,60.00
             426.92,58.32 415.94,63.02 409.00,65.33
             405.43,66.52 402.35,66.92 400.00,70.00
             394.18,68.87 388.30,71.78 382.73,73.31
             379.15,74.29 377.42,73.87 375.00,77.00
             375.00,77.00 372.00,78.00 372.00,78.00
             368.74,77.60 368.09,77.35 366.00,80.00
             366.00,80.00 363.00,81.00 363.00,81.00
             359.74,80.60 359.09,80.35 357.00,83.00
             357.00,83.00 354.00,84.00 354.00,84.00
             347.81,82.57 343.33,85.36 337.59,86.99
             333.75,88.08 331.64,87.56 329.00,91.00
             329.00,91.00 326.00,92.00 326.00,92.00
             326.00,92.00 323.00,93.00 323.00,93.00
             323.00,93.00 320.00,94.00 320.00,94.00
             320.00,94.00 317.00,95.00 317.00,95.00
             317.00,95.00 314.00,94.00 314.00,94.00
             314.00,94.00 314.00,96.00 314.00,96.00
             310.76,95.50 302.91,95.74 301.00,99.36
             300.59,100.85 300.99,106.05 301.00,108.00
             301.00,108.00 301.00,148.00 301.00,148.00
             301.00,148.00 301.00,164.91 301.00,164.91
             301.00,164.91 299.22,170.17 299.22,170.17
             298.26,174.88 298.68,176.16 296.37,181.00
             293.12,187.78 288.49,193.63 282.00,197.48
             278.11,199.79 272.64,200.55 270.00,204.00
             265.00,202.58 262.00,202.78 257.00,204.00
             257.00,204.00 238.44,191.71 238.44,191.71
             238.44,191.71 231.17,181.00 231.17,181.00
             231.17,181.00 227.00,173.00 227.00,173.00
             227.00,173.00 228.00,154.00 228.00,154.00
             228.00,154.00 228.00,120.00 228.00,120.00
             223.99,120.78 220.64,120.58 218.00,124.00
             211.40,123.14 202.37,127.21 196.00,129.33
             196.00,129.33 162.00,139.33 162.00,139.33
             162.00,139.33 115.00,153.66 115.00,153.66
             109.52,155.41 101.86,156.79 97.00,159.41
             86.02,165.33 81.87,175.12 82.00,187.00
             82.12,197.08 86.41,206.49 89.28,216.00
             89.28,216.00 104.98,268.00 104.98,268.00
             104.98,268.00 109.72,285.00 109.72,285.00
             109.72,285.00 133.04,363.00 133.04,363.00
             133.04,363.00 147.72,412.00 147.72,412.00
             147.72,412.00 155.63,438.00 155.63,438.00
             155.63,438.00 163.98,465.00 163.98,465.00
             163.98,465.00 171.28,490.00 171.28,490.00
             171.28,490.00 181.63,524.00 181.63,524.00
             185.38,533.19 195.18,543.83 206.00,541.00
             208.39,543.67 211.61,543.67 214.00,541.00
             218.14,542.04 224.09,541.43 227.00,538.00
             230.26,538.40 230.91,538.65 233.00,536.00
             233.00,536.00 236.00,535.00 236.00,535.00
             242.68,535.95 253.59,531.03 260.57,529.20
             264.50,528.17 267.34,528.47 270.00,525.00
             277.55,525.98 289.43,520.23 297.00,518.04
             301.09,516.85 304.31,517.46 307.00,514.00
             313.20,514.81 321.06,511.32 327.00,509.33
             330.39,508.20 332.76,507.92 335.00,505.00
             338.77,505.89 341.37,506.19 344.00,503.00
             348.05,503.50 357.47,501.27 360.00,498.00
             363.97,498.51 366.46,498.31 369.00,495.00
             374.87,495.84 383.42,492.32 389.27,490.70
             392.85,489.70 394.57,490.14 397.00,487.00
             400.97,487.51 403.46,487.31 406.00,484.00
             409.97,484.51 412.46,484.31 415.00,481.00
             419.61,481.93 430.96,479.70 434.00,476.00
             437.97,476.51 440.46,476.31 443.00,473.00
             446.97,473.51 449.46,473.31 452.00,470.00
             456.42,470.88 468.09,468.54 471.00,465.00
             474.97,465.51 477.46,465.31 480.00,462.00
             483.97,462.51 486.46,462.31 489.00,459.00
             494.94,460.15 502.56,456.65 508.41,455.01
             512.24,453.94 514.38,454.42 517.00,451.00
             520.97,451.51 523.46,451.31 526.00,448.00
             531.27,449.02 533.08,447.52 538.00,446.03
             547.42,443.19 555.08,441.67 561.37,433.00
             564.30,428.94 567.83,422.69 565.00,418.00
             567.21,416.02 567.21,414.98 565.00,413.00 Z
           M 192.00,106.00
           C 192.00,106.00 91.00,106.00 91.00,106.00
             91.00,106.00 64.00,106.00 64.00,106.00
             60.68,106.01 58.33,105.40 56.00,108.00
             47.35,106.25 41.24,110.89 36.81,118.00
             34.61,121.54 32.91,125.13 35.00,129.00
             31.55,133.83 33.00,146.76 33.00,153.00
             33.00,153.00 33.00,212.00 33.00,212.00
             33.00,212.00 33.00,441.00 33.00,441.00
             33.00,441.00 33.00,490.00 33.00,490.00
             33.09,499.27 35.14,508.46 43.04,514.36
             48.38,518.36 56.54,518.99 63.00,519.00
             63.00,519.00 156.00,519.00 156.00,519.00
             156.00,519.00 146.72,487.00 146.72,487.00
             146.72,487.00 130.72,434.00 130.72,434.00
             130.72,434.00 85.28,282.00 85.28,282.00
             85.28,282.00 70.72,234.00 70.72,234.00
             70.72,234.00 65.98,217.00 65.98,217.00
             63.62,209.41 57.36,191.84 58.29,184.34
             59.51,174.42 61.12,167.79 66.23,159.00
             69.44,153.48 74.29,149.81 76.00,144.00
             80.95,144.08 83.58,141.42 88.00,139.26
             93.33,136.66 100.29,134.06 106.00,132.43
             106.00,132.43 129.00,125.98 129.00,125.98
             129.00,125.98 153.00,118.28 153.00,118.28
             161.37,116.07 185.27,109.52 192.00,106.00 Z
           M 276.00,106.00
           C 272.09,106.53 263.58,107.96 261.00,111.00
             261.00,111.00 258.00,112.00 258.00,112.00
             258.00,112.00 255.00,113.00 255.00,113.00
             255.00,113.00 252.00,112.00 252.00,112.00
             250.34,117.83 251.00,124.90 251.00,131.00
             251.00,131.00 251.00,164.00 251.00,164.00
             251.02,168.60 251.10,172.20 254.39,175.86
             260.14,182.25 270.93,180.55 274.83,172.98
             276.21,170.30 276.00,166.95 276.00,164.00
             276.00,164.00 276.00,106.00 276.00,106.00 Z'
      />
    </svg>
  );
}

export default NotesIcon;
